<template>
	<div>
		<a-modal okText="确定" cancelText="取消" @cancel="cancel" v-model="show" title="修改菜单" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">
					
					<!-- 名称 -->
					<a-form-item label="id">
						<a-input disabled v-decorator="[
							'id',
							{
							  initialValue: record.data.id,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
										
					<!-- 选择服务，级联选择器 -->
					<a-form-item label="服务">
						<a-cascader :options="record.shop" expand-trigger="hover" @change="getcascader" v-decorator="[
								'service_id',
								{
								  initialValue:cascader ,
								  rules: [{ required: true, message: `请填写`}],
								},
							  ]" />
					</a-form-item>


					<!-- 名称 -->
					<a-form-item label="名称">
						<a-input v-decorator="[
							'name',
							{
							  initialValue: record.data.name,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					
					<!-- 图片icon -->
					<a-form-item label="点击更换icon(88X88)">
						<img v-decorator="[
							'icon',
							{
							  initialValue: record.data.icon,
							  rules: [{ required: true, message: `请填写`, whitespace: true }],
							},
						  ]" style="width: 22%;height: 60%;cursor: pointer;" :src="record.data.icon" alt="" @click="openresources(1)">
					</a-form-item>
					
					
					
				</a-form>
				<!-- 图片管理器 -->
				<resources ref="resources"  @getimgpath="getimgpath"></resources>
			</template>


		</a-modal>
	</div>
</template>

<script>
	import resources from "../resoureces.vue"
	export default {
		components: {
			resources
		},
		props: {
			record: Object
		},
		computed: {
			// 级联选中默认
			cascader() {
				if (this.record.shop) {
					let arr = []
					arr.push(this.record.shop[0].value)
					arr.push(this.record.shop[0].children[0].value)
					arr.push(this.record.shop[0].children[0].children[0].value)
					return arr
				}
			},

		},
		data() {
			return {
				show: false,
				isresources: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
			}
		},

		created() {

		},
		methods: {
			// 获取类型
			gettype(e) {
				// console.log(e, "类型");
				this.type = e.target.value.toString() 
				console.log(this.type, "类型");
			},
			// 获取商品
			getcascader(e) {
				console.log(e, "获取商品");
			},
			// 获取分类
			getselect(e) {
				console.log(e, "获取选中分类id");
			},
			// 打开图片管理器
			openresources(btn) {
				this.witchbtn  = btn
				this.$refs.resources.visibles = true
				// this.isresources = true
			},

			// 获取更改图片路径
			getimgpath(val) {
				// 收集数据集
				this.$emit("changeimg", val)
			},
			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					values.service_id = values.service_id[values.service_id.length-1]
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
			cancel(e){
				let pagination = {
					pageSize:4,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:""
				}
				this.$emit("gettabledata",pagination)
			}
		}
	}
</script>

<style scoped lang="less">

</style>
